@use '@angular/material' as mat;
// @import '~codemirror/lib/codemirror.css';
// @import '~codemirror/theme/idea.css';
// @import '~codemirror/addon/fold/foldgutter.css';
// @import '~codemirror/addon/lint/lint.css';

@import 'codemirror/lib/codemirror';
@import 'codemirror/theme/material';

@font-face {
  font-family: Goldman;
  src: url(./fonts/Goldman-Regular.ttf);
}

@font-face {
  font-family: GoldmanBold;
  src: url(./fonts/Goldman-Bold.ttf);
}

// @import '~codemirror/lib/codemirror';
// @import '~codemirror/theme/material';

/* // Define a custom typography config that overrides the font-family as well as the
// `headlines` and `body-1` levels. */
$custom-typography: mat.define-legacy-typography-config(
  $font-family: 'Roboto, monospace',
  $headline: mat.define-typography-level(32px, 48px, 700),
  $body-1: mat.define-typography-level(16px, 24px, 500),
);

$bg-gray: #f8f8f8;
$light-grey: #efefef;
$base-grey: #ededed;
$darker-grey: #d4d4d4;
$darkest-grey: #343434;
// $label-grey: #454545;
$label-grey: #2b2b2b;

$type-string: #bc2c2c;
$type-number: #3f93af;
$type-boolean: #288644;
$type-color: #ea9e34;
$type-option: #222628;

$base-blue: #0191f3;

.mat-tooltip {
  background-color: $darkest-grey; /* Change tooltip background */
  color: $bg-gray; /* Change text color */
  font-size: 12px; /* Adjust font size */
  border-radius: 4px; /* Add rounded corners */
  padding: 8px 12px; /* Add padding */
  max-width: 300px; /* Set a max width */
  text-align: center; /* Center-align the text */
}
.no-select {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
}

.list-item {
  // background: lightgray;
  padding: 10px 12px;
  border-radius: 6px;
  border: 1.5px solid $base-blue;
  display: flex;
  justify-content: space-between;
}

.drag-hover-mode {
  border-bottom: 1.5px solid $base-blue;
}

.list-item:hover {
  cursor: pointer;
  border: 2.5px solid $base-blue;
  padding: 9px 11px;
}

a {
  text-decoration: none;
  color: black;
}

.base-blue-text {
  color: $base-blue;
}

.no-wrap {
  white-space: nowrap;
}

.text-center {
  text-align: center;
}

.t-center {
  text-align: center;
}

.overflow-hidden {
  overflow: hidden;
}

.p-1 {
  padding: 2px;
}
.p-2 {
  padding: 2px;
}
.p-3 {
  padding: 3px;
}
.p-4 {
  padding: 4px;
}
.p-5 {
  padding: 5px;
}
.p-6 {
  padding: 6px;
}
.p-8 {
  padding: 8px;
}
.p-10 {
  padding: 10px;
}
.p-12 {
  padding: 12px;
}
.p-15 {
  padding: 15px;
}

.ph-1 {
  padding: 0 2px;
}
.ph-2 {
  padding: 0 2px;
}
.ph-3 {
  padding: 0 3px;
}
.ph-4 {
  padding: 0 4px;
}
.ph-5 {
  padding: 0 5px;
}
.ph-6 {
  padding: 0 6px;
}
.ph-8 {
  padding: 0 8px;
}
.ph-10 {
  padding: 0 10px;
}
.ph-12 {
  padding: 0 12px;
}
.ph-15 {
  padding: 0 15px;
}

.pv-1 {
  padding: 2px 0;
}
.pv-2 {
  padding: 2px 0;
}
.pv-3 {
  padding: 3px 0;
}
.pv-4 {
  padding: 4px 0;
}
.pv-5 {
  padding: 5px 0;
}
.pv-6 {
  padding: 6px 0;
}
.pv-8 {
  padding: 8px 0;
}
.pv-10 {
  padding: 10px 0;
}
.pv-12 {
  padding: 12px 0;
}
.pv-15 {
  padding: 15px 0;
}
.pv-20 {
  padding: 20px 0;
}

.mv-1 {
  margin: 1px 0;
}
.mv-2 {
  margin: 2px 0;
}
.mv-3 {
  margin: 3px 0;
}
.mv-4 {
  margin: 4px 0;
}
.mv-5 {
  margin: 5px 0;
}
.mv-6 {
  margin: 6px 0;
}
.mv-8 {
  margin: 8px 0;
}
.mv-10 {
  margin: 10px 0;
}
.mv-12 {
  margin: 12px 0;
}
.mv-16 {
  margin: 16px 0;
}
.mv-18 {
  margin: 18px 0;
}
.mv-20 {
  margin: 20px 0;
}

.mh-1 {
  margin: 0 1px;
}
.mh-2 {
  margin: 0 2px;
}
.mh-3 {
  margin: 0 3px;
}
.mh-4 {
  margin: 0 4px;
}
.mh-5 {
  margin: 0 5px;
}
.mh-6 {
  margin: 0 6px;
}
.mh-8 {
  margin: 0 8px;
}
.mh-10 {
  margin: 0 10px;
}
.mh-12 {
  margin: 0 12px;
}
.mh-16 {
  margin: 0 16px;
}
.mh-18 {
  margin: 0 18px;
}
.mh-20 {
  margin: 0 20px;
}

canvas {
  // position: absolute;
  // z-index: -100;
  // width: 100%;
  // height: 100%;
  // height: -webkit-fill-available;
  // width: -webkit-fill-available;
}

canvas {
  // width: 1216px;
  // height: 684px;
  width: 800px;
  height: 800px;
}

.position-relative {
  position: relative;
}

.position-absolute {
  position: absolute;
}

.editor-container {
  background: $bg-gray;
  border: 1.5px solid $label-grey;
  margin: 2px;
  border-radius: 2px;
}

.editor-container-header {
  font-size: 14px;
  text-align: center;
  width: 100%;
  border-bottom: 1.5px solid $label-grey;
  padding: 4px 0;
}

.editor-container-body {
  // padding: 6px;
}

.editor-footer {
  border-top: 1.5px solid $label-grey;
  padding: 4px 0;
}

.blue-border-top {
  border-top: 2px solid $base-blue;
}

.blue-border {
  border: 1px solid $base-blue !important;
}

.attribute-panel-delimiter-border {
  border-top: 2px solid $base-blue;
}

.attribute-value-section {
  padding: 2px 8px;
  margin-bottom: 4px;
  font-size: 12px;
}

.CodeMirror {
  height: 100% !important;
}

.fill-available-width {
  width: 100%;
  width: -moz-available; /* WebKit-based browsers will ignore this. */
  width: -webkit-fill-available; /* Mozilla-based browsers will ignore this. */
}

.fill-available-height {
  height: 100%;
  height: -moz-available; /* WebKit-based browsers will ignore this. */
  height: -webkit-fill-available; /* Mozilla-based browsers will ignore this. */
}

.fill-height {
  height: 100%;
  height: -moz-available; /* WebKit-based browsers will ignore this. */
  height: -webkit-fill-available; /* Mozilla-based browsers will ignore this. */
}

.height-100 {
  height: 100%;
}

.spinner-x {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  border: 1.5px solid transparent;
  border-top: 1.5px solid $base-blue; /* Color of the stroke from 0 hour */
  border-left: 1.5px solid $base-blue; /* Color of the stroke from 9 hour */
  animation: spin 1.5s linear infinite; /* Adjust duration for speed */
}

@keyframes spin {
  0% {
    transform: rotate(0deg); /* Start at 0 hour (top) */
  }
  100% {
    transform: rotate(360deg); /* Complete one full rotation */
  }
}

.pos-rel {
  position: relative;
}

.center-image {
  position: absolute;
  top: 50%;
  left: 90%;
  transform: translate(-50%, -50%);
}

.hidden-by-opacity {
  opacity: 0.001;
}

.iw-disabled {
  opacity: 0.6;
  pointer-events: none;
}

.blue-border {
  border: 1.5px solid $base-blue;
}

.fill-available {
  @extend .fill-available-height;
  @extend .fill-available-width;
}

/* You can add global styles to this file, and also import other style files */
.cursor-move {
  cursor: move;
}

.title {
  font-size: 14px;
  color: $darkest-grey;
  text-align: center;
}

.bold {
  font-weight: bold;
}

.label {
  font-size: 11px;
  color: $darkest-grey;
}

.cursor-pointer {
  cursor: pointer;
}

.pointer {
  cursor: pointer;
}

.italic {
  font-style: italic;
}

.underline {
  text-decoration: underline;
}

.underline-hover:hover {
  text-decoration: underline;
}

.hover-grab:hover {
  cursor: grab;
}

.hover-pointer:hover {
  cursor: grab;
}

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, 'Helvetica Neue', sans-serif;
}

.w-50 {
  width: 50%;
}

.w-100 {
  width: 100%;
  width: -moz-available; /* WebKit-based browsers will ignore this. */
  width: -webkit-fill-available; /* Mozilla-based browsers will ignore this. */
  width: stretch;
}

.h-50 {
  height: 50%;
}

.h-100 {
  height: 100%;
  height: -moz-available; /* WebKit-based browsers will ignore this. */
  height: -webkit-fill-available; /* Mozilla-based browsers will ignore this. */
  height: stretch;
}

.hidden {
  opacity: 0;
}

.display-none {
  display: none;
}

.display-block {
  display: block !important;
}

.i-18 {
  font-size: 18px;
  width: 18px !important;
  height: 18px !important;
}

.i-30 {
  font-size: 28px;
  width: 28px !important;
  height: 28px !important;
}

.i-40 {
  font-size: 40px;
  width: 40px !important;
  height: 40px !important;
}

.i-50 {
  font-size: 50px;
  width: 50px !important;
  height: 50px !important;
}

.m-2 {
  margin-top: 2px;
}

.mat-mdc-progress-spinner {
  --mdc-circular-progress-active-indicator-color: $darker-grey;
}

.mat-progress-spinner-indeterminate-animation {
  /* keep anim slow to avoid wobble from rounding errors */
  animation-duration: 9s !important;
}

.mat-progress-spinner-indeterminate-animation circle {
  animation-name: progress-spinner-stroke-rotate !important;
  animation-timing-function: linear !important;
  animation-duration: 4s !important;
}

// @keyframes progress-spinner-stroke-rotate {
//   $start: 200%; /* approx 2/3 of circle circumference */
//   $end: 20%;
//   0% {
//     stroke-dashoffset: $start;
//   }
//   50% {
//     stroke-dashoffset: $end;
//   }

//   /* removing transform: rotate(720deg) causes wobble */
//   100% {
//     stroke-dashoffset: $start;
//     transform: rotate(720deg);
//   }
// }

.mat-dialog-container {
  padding: 0px !important;
  border-radius: 10px;
  overflow: hidden !important;
}

.cdk-content {
  padding: 4px;
  border-radius: 4px;
  background-color: $base-grey;
  padding: 4px 12px;
  font-weight: bold;
  font-size: 12px;
  position: relative;
  top: 40px;
}

.blink {
  animation: blinker 1.5s infinite;
}

div {
  font-family: Goldman;
  color: $label-grey;
}

select,
option,
textarea,
input {
  font-family: Goldman;
  color: $label-grey;
  background: $bg-gray;
}

@keyframes blinker {
  from {
    opacity: 1;
  }
  50% {
    opacity: 0.2;
  }
  to {
    opacity: 1;
  }
}

.fs-10 {
  font-size: 10px;
}

.fs-11 {
  font-size: 11px;
}

.fs-12 {
  font-size: 12px;
}

.fs-13 {
  font-size: 13px;
}

.fs-14 {
  font-size: 14px;
}

.table-center {
  display: table;
  margin: 0 auto;
}

.flex {
  display: flex;
  align-items: center;
}

.flex-grow {
  flex-grow: 1;
}

.flex-row {
  display: flex;
  align-items: center;
}

.flex-column {
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.stretch {
  align-items: stretch;
}

.start {
  align-items: start;
}

.row-space-between {
  @extend .flex-row;
  justify-content: space-between;
}

.space-around {
  justify-content: space-around;
}

.space-between {
  justify-content: space-between;
}

.row-center-space-around {
  @extend .flex-row;
  justify-content: space-around;
}

.flex-justify-end {
  justify-content: end;
}

.flex-align-end {
  align-items: end;
}

.gap-2 {
  gap: 2px;
}

.gap-4 {
  gap: 4px;
}

.gap-6 {
  gap: 6px;
}

.gap-8 {
  gap: 8px;
}

.gap-10 {
  gap: 10px;
}

.gap-12 {
  gap: 12px;
}

.gap-14 {
  gap: 14px;
}

.gap-16 {
  gap: 16px;
}

.gap-18 {
  gap: 18px;
}

.gap-20 {
  gap: 20px;
}

// this a hack for removing a div in the canvas element, from which I don't really where it comes from
#_canvas > div {
  display: none;
}

input {
  font-size: 12px;
  height: 18px;
  border-radius: 3px;
  border: 1px solid black;
}

input[type='checkbox'] {
  height: 10px;
  width: 10px;
}

.base-number-input {
  width: 80px;
  background: #ededed;
  border-radius: 2px;
  border: none;
  padding: 2px 4px;
  font-size: 12px;
}

html {
  overflow: hidden;
}

.br-1 {
  border-radius: 1px;
}
.br-2 {
  border-radius: 2px;
}
.br-3 {
  border-radius: 3px;
}
.br-4 {
  border-radius: 4px;
}
.br-5 {
  border-radius: 5px;
}
.br-6 {
  border-radius: 6px;
}
.br-8 {
  border-radius: 8px;
}
.br-10 {
  border-radius: 10px;
}
.br-12 {
  border-radius: 12px;
}
